<template>
  <modal
    name="campaignModal"
    class="unifiedcampaign-modal"
    @closed="hideWelcomePage"
    :clickToClose="false"
    :scrollable="true"
    :height="'auto'"
  >
    <div class="">
      <div class="w-100 modal-height">
        <div class="row pb-5">
          <div class="col p-0 welcome mt-4 align-start">
            <img
              src="../../assets/group-21.png"
              alt
              class="welcome--image img img-responsive"
            />
          </div>
          <div class="col p-0 intro__content ">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">
              {{ this.modalTitle }}
            </p>
            <div
              class="w-100 Together-let-us-hel ProximaNovaRegular mt-0"
              style="height: auto"
            >
              <div class="mb-5">
                <b-form-radio-group
                  v-model="selected"
                  :options="options"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
              <div class="px-5">
                <w-new-unified-campaign
                  :buttonLoader="buttonLoader"
                  :style="
                    selected === 'new' ? 'display: block' : 'display: none'
                  "
                  :totalRows="totalRows"
                  @createNewCmapaign="createNewCmapaign($event)"
                  @hideModal="hideWelcomePage"
                ></w-new-unified-campaign>
                <w-existing-unified-campaign
                  :style="
                    selected === 'existing' ? 'display: block' : 'display: none'
                  "
                  :totalRows="totalRows"
                  @createNewCmapaign="createNewCmapaign($event)"
                  @hideModal="hideWelcomePage"
                ></w-existing-unified-campaign>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import NewUnifiedCampaign from "@/pages/Forms/NewUnifiedCampaign.vue";
import ExistingUnifiedCampaign from "@/pages/Forms/ExistingUnifiedCampaign.vue";

export default {
  name: "UnifiedCampaignModal",
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    buttonLoader: {
      type: String,
      default: "normal",
    },
  },
  data() {
    return {
      isActive: 0,
      modalTitle: "",
      selectedFilter: "",
      modalShow: false,
      selected: "new",
      options: [
        { item: "new", name: "New Unified Campaign" },
        { item: "existing", name: "Assign Data Stream to Existing Campaign" },
      ],
    };
  },
  components: {
    "w-new-unified-campaign": NewUnifiedCampaign,
    "w-existing-unified-campaign": ExistingUnifiedCampaign,
  },

  methods: {
    open(title) {
      this.modalTitle = title;
      this.$modal.show("campaignModal");
    },
    hideWelcomePage() {
      this.$modal.hide("campaignModal");
    },
    createNewCmapaign(e) {
      this.$emit("createCampaign", e);
    },
  },
};
</script>
<style>
.custom-control-label::after,
.custom-control-label::before {
  height: 2rem !important;
  width: 2rem !important;
}
.custom-control-label span {
  margin-left: 2rem;
}
.unifiedcampaign-modal .vm--modal {
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
.unifiedcampaign-modal .vm--overlay {
  background: rgba(0, 0, 0, 0.9) !important;
}
</style>
<style lang="css" scoped></style>
