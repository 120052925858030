<template>
  <div>
    <div class="Together-let-us-hel ProximaNovaRegular mt-0 h-100">
      <w-dropdown
        :options="unifiedCampaignList"
        :placeHolder="'Select'"
        :labelText="'Unified Campaign'"
        class="mb-5"
        @input="selectCampaign($event)"
        :selectedOption="selectedUnifiedCampaign"
      >
      </w-dropdown>
      <w-tree-dropdown
        :placeHolder="'Select Channels'"
        class="mb-5"
        :rootOptions="channelList"
        :selectedValue="selectedChannels"
        @selectedField="getSelectedChannel($event)"
        @closedMenu="channelClosed($event)"
      >
      </w-tree-dropdown>
      <w-tree-dropdown
        :placeHolder="'Select Datastreams'"
        class="mb-5"
        :rootOptions="datastreamList"
        :selectedValue="selecteddataStreams"
        @selectedField="getSelectedDataStream($event)"
        @closedMenu="menuClosed($event)"
      >
      </w-tree-dropdown>

      <date-picker
        v-model="customDate"
        class="date-picker"
        ref="datePicker"
        format="MMM YYYY"
        range
        confirm
        confirm-text="Done"
      ></date-picker>
      <w-white-dropdown
        :options="options"
        :placeHolder="'Date'"
        :class="startDate !== '' && endDate !== '' ? 'mb-1' : 'mb-5'"
        class="date-picker w-100  p-0"
        :labelText="'Date'"
        :selectedOption="selectedFilter"
        @input="selectFilter($event)"
      >
      </w-white-dropdown>
      <div
        class="display-daterange mb-5"
        v-if="startDate !== '' && endDate !== ''"
      >
        Selected date :
        {{ startDate }} -
        {{ endDate }}
      </div>
      <w-tree-dropdown
        :placeHolder="'Select Customers'"
        class="mb-5"
        :rootOptions="customerList"
        :selectedValue="selectedCustomer"
        @selectedField="getSelectedCustomer($event)"
        @closedMenu="accountsList($event)"
      >
      </w-tree-dropdown>
      <w-tree-dropdown
        :placeHolder="'Select Campaign Type'"
        class="mb-5"
        :rootOptions="campaignList"
        :selectedValue="selectedCampaign"
        @selectedField="getSelectedCampaign($event)"
        @closedMenu="adTypeList($event)"
      >
      </w-tree-dropdown>
      <w-tree-dropdown
        :placeHolder="'Select Campaign Name'"
        class="mb-5"
        :rootOptions="campaignNameList"
        :selectedValue="selectedCampaignName"
        @selectedField="getSelectedCampaignName($event)"
      >
      </w-tree-dropdown>
      <div class=" mt-3 content-center w-100 d-grid">
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Create Unified Campaign'"
          :isDisable="isDisable"
          :class="{ resetPasswordLink: isDisable }"
          @buttonClicked="createNewCmapaign"
        >
        </w-button>
        <div
          class="editProfile ProximaNovaRegular content-center mt-3"
          @click="$emit('hideModal')"
        >
          <span>No, I'll do later</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import DatePicker from "vue2-datepicker";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
import "@/assets/style/datepicker/index.css";
import { DataConnection } from "../../services/DataConnectionService.js";
const dataConnection = new DataConnection();
import Dropdown from "../../widgets/Dropdown.vue";
import moment from "moment";
export default {
  components: {
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    DatePicker,
    "w-tree-dropdown": Treeselect,
    "w-dropdown": Dropdown,
  },
  computed: {
    isDisable() {
      if (
        this.selectedChannels.length !== 0 &&
        this.selectedCampaign.length !== 0 &&
        this.selectedCampaignName.length !== 0 &&
        this.selectedCustomer.length !== 0 &&
        this.startDate !== "" &&
        this.endDate !== "" &&
        Object.keys(this.selectedUnifiedCampaign).length !== 0
      ) {
        return false;
      }
      return true;
    },
  },
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    buttonLoader: {
      type: String,
      default: "normal",
    },
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
      }
    },
  },
  data() {
    return {
      unifiedCampaignList: [],
      selectedUnifiedCampaign: {},
      selectedFilter: "",
      selecteddataStreams: [],
      customerList: [],
      channelList: [],
      selectedChannels: [],
      campaignList: [],
      selectedCampaign: [],
      campaignNameList: [],
      selectedCampaignName: [],
      selectedCustomer: [],
      unifiedName: "",
      channels: [
        { text: "Preload", id: "Preload" },
        { text: "Digital media", id: "Digital media" },
        { text: "Analytics", id: "Analytics" },
        { text: "App Analytics", id: "App Analytics" },
      ],
      options: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "last7days" },
        { text: "Last 30 Days", id: "30Days" },
        { text: "This Month", id: "thisMonth" },
        { text: "Last Month", id: "lastMonth" },
        { text: "Last Year", id: "lastYear" },
        { text: "Custom Select", id: "customSelect" },
      ],
      datastreamList: [],
      startDate: "",
      endDate: "",
      customDate: [],
    };
  },
  methods: {
    createNewCmapaign() {
      var campName = this.selectedCampaignName.filter((name) => name !== "all");
      var channels = this.selectedChannels.filter((name) => name !== "all");
      var datastreams = this.selecteddataStreams.filter(
        (name) => name !== "all"
      );
      var customer = this.selectedCustomer.filter((name) => name !== "all");
      var campaignType = this.selectedCampaign.filter((name) => name !== "all");
      var data = {
        unifiedCampaignName: null,
        existingCampaign: this.selectedUnifiedCampaign.id,
        channel: channels,
        customers: customer,
        campaignType: campaignType,
        campaignName: campName,
        dataStreamId: datastreams,
        startDate: this.startDate,
        endDate: this.endDate,
        orgNumber: sessionStorage.getItem("subId"),
      };
      this.$emit("createNewCmapaign", data);
    },
    selectCampaign(e) {
      this.selectedUnifiedCampaign = e;
    },
    selectFilter(e) {
      this.customDate = [];
      this.selectedFilter = e;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
      } else if (e && e.id === "today") {
        this.startDate = moment(new Date()).format("YYYY-MM-DD");
        this.endDate = moment(new Date()).format("YYYY-MM-DD");
      } else if (e && e.id === "last7days") {
        this.startDate = moment(
          new Date().setDate(new Date().getDate() - 7)
        ).format("YYYY-MM-DD");
        this.endDate = moment(
          new Date().setDate(new Date().getDate() - 1)
        ).format("YYYY-MM-DD");
      } else if (e && e.id === "30Days") {
        this.endDate = moment(
          new Date().setDate(new Date().getDate() - 1)
        ).format("YYYY-MM-DD");
        this.startDate = moment(
          new Date().setDate(new Date().getDate() - 31)
        ).format("YYYY-MM-DD");
      } else if (e && e.id === "lastMonth") {
        let dateObj = new Date();
        this.startDate = moment(
          new Date(dateObj.getFullYear(), dateObj.getMonth() - 1, 1)
        ).format("YYYY-MM-DD");
        this.endDate = moment(
          new Date(dateObj.getFullYear(), dateObj.getMonth(), 0)
        ).format("YYYY-MM-DD");
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        this.startDate = moment(dateObj).format("YYYY-MM-DD");
        this.endDate = moment(dateObj).format("YYYY-MM-DD");
      } else if (e && e.id === "lastWeek") {
        const date = new Date();
        const today = date.getDate();
        const dayOfTheWeek = date.getDay();
        date.setDate(today + 1 - dayOfTheWeek - 7);
        this.startDate = moment(date).format("YYYY-MM-DD");
        date.setDate(today - dayOfTheWeek);
        this.endDate = moment(date).format("YYYY-MM-DD");
      } else if (e && e.id === "thisMonth") {
        const date = new Date();
        let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.startDate = moment(firstDate).format("YYYY-MM-DD");
        this.endDate = moment(date).format("YYYY-MM-DD");
      } else if (e && e.id === "lastYear") {
        const date = new Date();
        let firstDate = new Date(date.getFullYear() - 1, 0, 1);
        let lastDate = new Date(date.getFullYear() - 1, 11, 31);
        this.startDate = moment(firstDate).format("YYYY-MM-DD");
        this.endDate = moment(lastDate).format("YYYY-MM-DD");
      }
    },
    getSelectedDataStream(e) {
      if (e.includes("all")) {
        this.selecteddataStreams = [];
        for (var i = 0; i < this.datastreamList.length; i++) {
          this.selecteddataStreams.push(this.datastreamList[i].id);
        }
      } else {
        this.selecteddataStreams = e;
      }
    },
    getSelectedChannel(e) {
      if (e.includes("all")) {
        this.selectedChannels = [];
        for (var i = 0; i < this.channelList.length; i++) {
          this.selectedChannels.push(this.channelList[i].id);
        }
      } else {
        this.selectedChannels = e;
      }
      this.selecteddataStreams = [];
    },
    getSelectedCampaign(e) {
      if (e.includes("all")) {
        this.selectedCampaign = [];
        for (var i = 0; i < this.campaignList.length; i++) {
          this.selectedCampaign.push(this.campaignList[i].id);
        }
      } else {
        this.selectedCampaign = e;
      }
    },
    getSelectedCampaignName(e) {
      if (e.includes("all")) {
        this.selectedCampaignName = [];
        for (var i = 0; i < this.campaignNameList.length; i++) {
          this.selectedCampaignName.push(this.campaignNameList[i].id);
        }
      } else {
        this.selectedCampaignName = e;
      }
    },
    getSelectedCustomer(e) {
      if (e.includes("all")) {
        this.selectedCustomer = [];
        for (var i = 0; i < this.customerList.length; i++) {
          this.selectedCustomer.push(this.customerList[i].id);
        }
      } else {
        this.selectedCustomer = e;
      }
    },
    getUnifiedCampaignDetails(data) {
      this.customerList = [];
      this.campaignList = [];
      this.selectedCampaign = [];
      this.selectedCustomer = [];
      dataConnection
        .getUnifiedCampaignDetails(data.filter((name) => name !== "all"))
        .then((res) => {
          if (res.response.customers.length !== 0) {
            this.customerList.push({ id: "all", label: "Select All" });
          }
          if (res.response.adType.length !== 0) {
            this.campaignList.push({ id: "all", label: "Select All" });
          }
          for (var i = 0; i < res.response.customers.length; i++) {
            this.customerList.push({
              id: res.response.customers[i],
              label: res.response.customers[i],
            });
          }
          for (i = 0; i < res.response.adType.length; i++) {
            this.campaignList.push({
              id: res.response.adType[i],
              label: res.response.adType[i],
            });
          }
        });
    },
    menuClosed(e) {
      if (e.length !== 0) {
        this.getUnifiedCampaignDetails(e);
      }
    },
    channelClosed(e) {
      if (e.length !== 0) {
        this.getDataStreamList();
      }
    },
    accountsList(e) {
      if (
        e.length !== 0 &&
        this.selectedCampaign.length !== 0 &&
        this.selectedCustomer.length !== 0
      ) {
        this.getCampaign();
      }
    },
    adTypeList(e) {
      if (
        e.length !== 0 &&
        this.selectedCampaign.length !== 0 &&
        this.selectedCustomer.length !== 0
      ) {
        this.getCampaign();
      }
    },
    getDataStreamList() {
      this.datastreamList = [];
      this.selecteddataStreams = [];
      dataConnection
        .getDataStreamByChannel(
          this.selectedChannels.filter((name) => name !== "all")
        )
        .then((res) => {
          this.datastreamList.push({ id: "all", label: "Select All" });
          for (var i = 0; i < res.response.length; i++) {
            this.datastreamList.push({
              id: res.response[i].adverityDatastreamId,
              label: res.response[i].datastreamName,
            });
          }
        });
    },
    getCampaignList() {
      this.unifiedCampaignList = [];
      dataConnection.getExistingUnifiedCampaignList().then((res) => {
        for (var i = 0; i < res.length; i++) {
          this.unifiedCampaignList.push({
            id: res[i].id,
            text: res[i].unifiedCampaignName,
          });
        }
      });
    },
    getChannelList() {
      this.channelList = [];
      dataConnection.getChannelList().then((res) => {
        this.channelList.push({ id: "all", label: "Select All" });
        for (var i = 0; i < res.response.length; i++) {
          this.channelList.push({
            id: res.response[i],
            label: res.response[i],
          });
        }
      });
    },
    getCampaign() {
      if (
        this.selecteddataStreams.length === 0 ||
        this.selectedCustomer.length === 0 ||
        this.selectedCampaign.length === 0
      ) {
        return;
      }
      this.campaignNameList = [];
      this.selectedCampaignName = [];

      dataConnection
        .getCampaignList({
          account: this.selectedCustomer.filter((name) => name !== "all"),
          dataStreamIds: this.selecteddataStreams.filter(
            (name) => name !== "all"
          ),
          adTypes: this.selectedCampaign.filter((name) => name !== "all"),
        })
        .then((res) => {
          this.campaignNameList = [{ id: "all", label: "Select All" }];
          for (var i = 0; i < res.response.campaignName.length; i++) {
            this.campaignNameList.push({
              id: res.response.campaignName[i],
              label: res.response.campaignName[i],
            });
          }
        });
    },
  },
  created() {
    // this.getDataStreamList();
    this.getChannelList();
    this.getCampaignList();
  },
};
</script>
<style>
.button.update {
  font-size: medium;
}
</style>
<style scoped>
.date-picker.mx-datepicker.mx-datepicker-range {
  visibility: hidden;
}
input#exampleInputEmail1 {
  box-shadow: none;
  border: 0;
}

::placeholder {
  color: #9aafd4;
  opacity: 1;
  font-weight: normal;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}

.btn-effect-intro {
  width: 78% !important;
  margin-top: 0 !important;
}
>>> .custom-control-label::before {
  width: 2rem !important;
  height: 2rem !important;
}
>>> .custom-control-label::after {
  top: -7px;
  left: -2.5rem;
  width: 4rem;
  height: 4rem;
}
.dropdownWrapper {
  width: 50%;
}
.date-picker.mx-datepicker.mx-datepicker-range {
  width: 45px;
  height: 0;
  position: absolute;
  top: 1510;
  left: 250;
  opacity: 0.8;
  right: -1rem;
}
.w-100.modal-height {
  height: 670px;
}
.customers-dropdown {
  z-index: 999;
}
.campaign-dropdown {
  z-index: 9999;
}
>>> .items {
  height: 166px !important;
  overflow-x: scroll;
}
.button {
  max-width: 280px;
}
.w-100.modal-height {
  height: auto !important;
}
.campaign-input {
  margin-right: 15px;
}
.d-flex.dropdownWrapper.date-picker {
  background-color: #eff5ff;
}
.custom-select {
  background-color: #eff5ff;
  z-index: 1 !important;
}
>>> .custom-select,
>>> .selected.font-medium.default,
>>> .selected.font-medium {
  background-color: #eff5ff !important;
}
>>> .selected.font-medium {
  color: #9aafd4;
  font-family: ProximaNovaRegular;
  font-weight: normal !important;
}
.display-daterange {
  font-size: 16px;
  font-family: "ProximaNovaRegular";
}
</style>
