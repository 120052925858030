<!-- Vue SFC -->
<template>
  <div>
    <label class="tree-label" v-if="labelText !== ''">{{ labelText }}</label>
    <treeselect
      :multiple="true"
      :closeOnSelect="false"
      :alwaysOpen="false"
      :clearable="false"
      :options="rootOptions"
      :placeholder="placeHolder"
      v-model="value"
      @input="getSelectedField"
      :loadOptions="loadOptions"
      @search-change="searchOption"
      :searchable="true"
      :maxHeight='200'
      @close="close"
    />
  </div>
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
var timer;
export default {
  components: { Treeselect },
  props: {
    placeHolder: {
      type: String,
      default: "Select",
    },
    rootOptions: {
      type: Array,
      default: () => [],
    },
    selectedValue: {
      type: Array,
      default: () => [],
    },
    labelText: {
      type: String,
      default: "",
    },
  },
  name: "MultiSelect",
  data() {
    return {
      value: null,
      options: null,
    };
  },
  created() {
    this.value = this.selectedValue;
    this.options = this.rootOptions
  },
  beforeUpdate() {
    this.value = this.selectedValue;
    this.options = this.rootOptions
  },
  methods: {
    searchOption(e) {
      var self = this;
      clearTimeout(timer);
      timer = setTimeout(function() {
        if(e !== ""){
          self.$emit("searchQuery", e);
        }
        
      }, 2000);
    },
    getSelectedField() {
      //this.selectedValue = node;
      this.$emit("selectedField", this.value);
    },
    close(e) {
      this.$emit("closedMenu", e);
    },
    loadOptions({ action }) {
      console.log(action)
    },
  },
};
</script>
<style>
.vue-treeselect__control {
  padding: 6px;
  border: 0px solid #3b475a;
  background-color: #eff5ff;
  font-size: small;
}
.vue-treeselect__input{
  height: auto !important;
  min-height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-disabled {
  background: #eff5ff;
  color: #3b475a;
}
.vue-treeselect__menu-container {
  font-size: medium;
}
.vue-treeselect__control:hover {
  border: 0;
  cursor: pointer;
}
.vue-treeselect__label {
  color: #3b475a !important;
  font-family: ProximaNovaSemiBold;
}

.tree-label {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  font-size: small;
  color: #313846;
}
.vue-treeselect__menu {
  border: 1px solid #3b475a;
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px #c9ced5;
}
.vue-treeselect__option {
  background: #fff;
  border-top: 0.5px solid #fff;
}
.vue-treeselect__option.vue-treeselect__option--selected {
  background-color: #eff5ff;
}
.vue-treeselect__option:hover {
  background-color: #eff5ff;
  font-family: ProximaNovaBold;
}
.vue-treeselect__multi-value-item {
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px #313846;
  color: #313846;
  border-radius: 10px;
  font-family: ProximaNovaBold;
}
.vue-treeselect__icon.vue-treeselect__value-remove {
  color: #313846;
}
.vue-treeselect__placeholder {
  align-items: center;
  font-size: medium;
  color: #9aafd4;
  font-family: "ProximaNovaRegular";
}
</style>
