<template>
  <div>
    <div class="w-100">
      <div class="row m-0 p-0" style="justify-content: end;">
        <w-button
          style="width: auto;"
          :buttonLoader="'none'"
          :buttonText="'Create Compaign'"
          @buttonClicked="createCampaign"
        >
        </w-button>
      </div>
    </div>
    <UnifiedCampaignModal
      ref="unifiedCampaignModal"
      :totalRows="totalRows"
      @createCampaign="campaignCreated"
    />
  </div>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
import UnifiedCampaignModal from "@/components/Modal/UnifiedCampaignModal.vue";
import { DataConnection } from "@/services/DataConnectionService.js";
const dataConnection = new DataConnection();
export default {
  props: {
    // totalRows: { type: Number, default: 0 },
  },
  components: {
    "w-button": Button,
    UnifiedCampaignModal,
  },
  data() {
    return {
      totalRows: 0,
      buttonLoader: "normal",
    };
  },
  methods: {
    getDataStreamSummaryTable() {
      dataConnection.getDataStreamSummaryTableData(1, 5).then((res) => {
        this.totalRows = res.totalElements;
      });
    },
    createCampaign() {
      this.$refs.unifiedCampaignModal.open("Unified Campaign");
    },
    campaignCreated(e) {
      this.buttonLoader = "loader";
      dataConnection.postNewCampaignData(e).then((res) => {
        this.$refs.unifiedCampaignModal.hideWelcomePage();
        console.log(res);
        this.$emit('campaignCreationDone')
        this.buttonLoader = "normal";
      });
    },
  },
  created() {},
};
</script>

<style></style>
