<template>
  <div>
    <div
      class="page-wrapper chiller-theme"
      style="margin-top: 71px; margin-left: 25px"
    >
      <main class="page-content">
        <div class="container-fluid">
          <!-- topbar -->
          <div class="d-flex justify-content-between my-2">
            <div>
              <ul
                class="breadcrumb ProximaNovaRegular"
                style="margin-top: 10px; margin-left: 10px"
              >
                <li>
                  Solutions
                </li>
                <li class="ProximaNovaBold">Health Monitor</li>
              </ul>
            </div>
          </div>
          <div class="w-100 my-3">
            <CreateCampaign @campaignCreationDone="campaignCreationDone" />
          </div>
          <HealthTable ref="healthMonitor" />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import HealthTable from "@/components/HealthMonitor/HealthTable.vue";
import CreateCampaign from "@/widgets/CreateCampaign.vue";

export default {
  props: [],
  components: {
    HealthTable,
    CreateCampaign,
  },
  data() {
    return {};
  },
  methods: {
    campaignCreationDone() {
      this.$refs.healthMonitor.getHealthMonitorList();
    },
  },
};
</script>
